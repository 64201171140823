<!-- 修改 -->
<template>
  <div class="apply" v-loading="loading">
    <back />

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">

      <div class="form">
        <div class="title">基本信息:</div>
        <el-row class="dialog_row">
          <el-col :span="8">
            <el-form-item class="item" label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="规格" prop="specs">
              <el-input v-model="ruleForm.specs" readonly></el-input>
            </el-form-item>
            <el-form-item class="item day" label="保质期" prop="validityPeriod">
              <el-input v-model="ruleForm.validityPeriod" type="number" readonly>
                <template slot="append">天</template></el-input>
            </el-form-item>
            <el-form-item class="item logo_pic" label="商品图片" prop="totalPic">
              <div class="pic-row">
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess1" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo1" :disabled="ruleForm.picUrl1 ? true : false">
                    <img v-if="ruleForm.picUrl1" :src="ruleForm.picUrl1" class="logoimg">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="ruleForm.picUrl1" class="delpic delpic1 el-icon-error" @click="handleRemoveLogo1"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess2" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo2" :disabled="ruleForm.picUrl2 ? true : false">
                    <img v-if="ruleForm.picUrl2" :src="ruleForm.picUrl2" class="logoimg">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="ruleForm.picUrl2" class="delpic delpic2 el-icon-error" @click="handleRemoveLogo2"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess3" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo3" :disabled="ruleForm.picUrl3 ? true : false">
                    <img v-if="ruleForm.picUrl3" :src="ruleForm.picUrl3" class="logoimg">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="ruleForm.picUrl3" class="delpic delpic3 el-icon-error" @click="handleRemoveLogo3"></i>
                </div>
              </div>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="item" label="店铺名称" prop="shopName">
              <el-input v-model="ruleForm.shopName" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="入箱数" prop="enterBox">
              <el-input v-model="ruleForm.enterBox" type="number" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="品牌" prop="brand">
              <el-input v-model="ruleForm.brand" readonly></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <!-- <el-form-item class="item" label="商品编号" prop="serialId">
              <el-input v-model="ruleForm.serialId" readonly></el-input>
            </el-form-item> -->
            <el-form-item class="item" label="商品条形码" prop="barCode">
              <el-input v-model="ruleForm.barCode" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="厂家" prop="factoryName">
              <el-input v-model="ruleForm.factoryName" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="商品类目" prop="categoryName">
              <el-input v-model="ruleForm.categoryName" readonly></el-input>
            </el-form-item>

          </el-col>
        </el-row>
        <div class="title">价格信息:</div>
        <el-row class="dialog_row">
          <!-- <el-col :span="8">
            <el-form-item class="item" label="供货价(元)" prop="supplyPrice">
              <el-input v-model="ruleForm.supplyPrice" readonly></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item class="item" label="建议零售价(元)" prop="salePrice">
              <el-input v-model="ruleForm.salePrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="item" label="划线价(元)" prop="linePrice">
              <el-input v-model="ruleForm.linePrice"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog_row">
          <el-col>
            <el-form-item label="商品介绍" prop="productIntroduce">
              <vue-word-editor v-model="ruleForm.productIntroduce" :config="config" ref="wordeditor"></vue-word-editor>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div class="submit-btn">
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button type="primary" plain @click="$router.go(-1)">取消</el-button>
      </div>
    </el-form>

  </div>
</template>

<script>
import VueWordEditor from "vue-word-editor";
import "quill/dist/quill.snow.css"
export default {
  components: {
    VueWordEditor,
  },
  data() {
    const priceRules = [{
      required: true,
      message: '请填写此项',
      trigger: 'blur'
    }, {
      validator: this.validatePrice,
      trigger: 'blur'
    }];
    return {
      loading: false,
      id: '',
      ruleForm: {
        supplierName: '', // 供应商名称
        serialId: '', // 商品编号
        barCode: '', // 商品条形码
        factoryName: '', // 厂家
        categoryName: '', // 商品类目
        productName: '', // 商品名称
        specs: '', // 规格
        validityPeriod: '', // 保质期
        shopName: '', // 店铺名称
        enterBox: '', // 入箱数
        brand: '', // 品牌
        supplyPrice: '', // 供货价
        salePrice: '', // 售货价
        linePrice: '', // 划线价

        picUrl1: "", //商品图片
        picUrl2: "", //商品图片
        picUrl3: "", //商品图片
        totalPic: "", //商品图片校验
        productId: "",
        productIntroduce: "", //介绍

      },

      rules: {
        salePrice: priceRules,
        // linePrice: priceRules,
        // totalPic: [{
        //  validator: () => {
        //    if (!this.ruleForm.picUrl1 && !this.ruleForm.picUrl2 && !this.ruleForm.picUrl3) {
        //    return Promise.reject("请至少上传一张图片");
        //    } else {
        //     return Promise.resolve();
        //   }
        // },
        // trigger: "blur"
        //}]
      },

      // 图片上传
      fileList: [],
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,

      config: {
        placeholder: '请输入内容',
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link", "image"],
            [{
              header: 1
            }, {
              header: 2
            }], // custom button values
            [{
              list: "ordered"
            },
            {
              list: "bullet"
            },
            {
              list: "check"
            },
            ],
            [{
              script: "sub"
            }, {
              script: "super"
            }], // superscript/subscript
            [{
              indent: "-1"
            }, {
              indent: "+1"
            }], // outdent/indent
            [{
              direction: "rtl"
            }], // text direction
          ],
        },
        // 上传图片的配置
        uploadImage: {
          url: this.$axios.defaults.baseURL + this.$api.uploadFile,
          name: "multipartFile",
          // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
          uploadSuccess: (res, insert) => {
            if (res.data.code !== 100) {
              this.$message.error("请上传图片格式，如JPG，JPEG，PNG文件格式");
            } else {
              insert(res.data.result.fileUrl);
            }
          },
          showProgress: false,
        },
      },
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getShopProductDetailById();
  },
  methods: {
    getShopProductDetailById() {
      this.loading = true;
      this.$axios.get(`${this.$api.getShopProductDetailById}/${this.id}`).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result;
          if (this.ruleForm.picUrlList && Array.isArray(this.ruleForm.picUrlList)) {
            this.$set(this.ruleForm, 'picUrl1', this.ruleForm.picUrlList[0] || '');
            this.$set(this.ruleForm, 'picUrl2', this.ruleForm.picUrlList[1] || '');
            this.$set(this.ruleForm, 'picUrl3', this.ruleForm.picUrlList[2] || '');
          } else {
            this.$set(this.ruleForm, 'picUrl1', '');
            this.$set(this.ruleForm, 'picUrl2', '');
            this.$set(this.ruleForm, 'picUrl3', '');
          }
          this.$refs.wordeditor.editor.root.innerHTML = this.ruleForm.productIntroduce;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 表单校验
    submitForm(formName) {
      this.ruleForm.productIntroduce = this.$refs.wordeditor.editor.root.innerHTML
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.put(this.$api.updateProduct, {
            id: this.id, // id
            linePrice: this.ruleForm.linePrice,
            productIntroduce: this.ruleForm.productIntroduce,
            salePrice: this.ruleForm.salePrice,
            productId: this.ruleForm.productId,
            shopId: this.$store.state.userInfo.shopId,
            picUrlList: [this.ruleForm.picUrl1 || '', this.ruleForm.picUrl2 || '', this.ruleForm.picUrl3 || '']
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
              this.$router.push('/product/all');
              this.resetForm();
            }
          }).catch(() => {
            this.$message.error('处理失败');
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
      this.ruleForm.productIntroduce = '';
    },
    handleRemoveLogo1() {
      this.ruleForm.picUrl1 = ""
    },
    handleSuccess1(res) {
      if (res.code == 100) {
        this.ruleForm.picUrl1 = res.result.fileUrl;
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        });
      }
    },
    handleRemoveLogo2() {
      this.ruleForm.picUrl2 = ""
    },
    handleSuccess2(res) {
      if (res.code == 100) {
        this.ruleForm.picUrl2 = res.result.fileUrl;
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        });
      }
    },
    handleRemoveLogo3() {
      this.ruleForm.picUrl3 = ""
    },
    handleSuccess3(res) {
      if (res.code == 100) {
        this.ruleForm.picUrl3 = res.result.fileUrl;
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        });
      }
    },
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt5M;
    },

    validatePrice(rule, value, callback) {
      const reg = /^\d+(\.\d{1,2})?$/;
      if (!reg.test(value)) {
        callback(new Error(`必须为数字且最多保留两位小数`));
      } else {
        callback();
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.apply {

  // 1.基本信息
  .form {
    margin: 30px 20px;

    .title {
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: bold;
    }

  }

  // 保质期天数
  .day {

    /deep/ .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    /deep/.el-input__inner {
      background: #f2f2f2;
      border-radius: 10px 0 0 10px;
      border: none;
    }

    /deep/ .el-input-group__append {
      background: #f2f2f2;
      border-radius: 0px 10px 10px 0;
      border: none;
      color: #000;
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  // 图片
  .logo_pic {
    position: relative;

    ::v-deep .el-form-item__content {
      width: 100px;
      height: 100px;
    }

    .pic-row {
      display: flex;

      .pic {
        margin-right: 15px;

        .logo_uploader {
          ::v-deep .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          ::v-deep .el-upload:hover {
            border-color: #069BBC;
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #069BBC;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          }

          .logoimg {
            width: 100px;
            height: 100px;
            display: block;
            object-fit: cover;
          }
        }
      }
    }

    .delpic {
      position: absolute;
      top: -12px;
      color: red;
      font-size: 20px;
      cursor: pointer;
    }

    .delpic1 {
      right: -15px;
    }

    .delpic2 {
      right: -134px;
    }

    .delpic3 {
      right: -250px;
    }
  }

}

// common
.dialog_row {

  // width: 850px;
  /deep/.el-form-item {
    margin-right: 100px;
  }

  /deep/.el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }
}

/* input number类型取消增减箭头*/
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// 光标上移
/deep/.el-input__inner {
  line-height: 1px !important;
}

/deep/ .ql-editor {
  min-height: 200px;
  max-height: 500px;
}
</style>
